

.Landing-Page {
    display: flex;
    flex-direction: column;
    align-items:safe;
    justify-content: space-between;
}

.Center-Content {
    width: 100%;
    text-align: center;
}


.Text {
  text-align: center;  
  font-family: 'Irish Grover';
  font-size: 2vw;
  background-color: transparent;
  color: #F8E9D2;
  border: none;
}

.FooterText {
  font-family: 'Irish Grover';
}


.Footer {
    height: 10%;
    text-align: center;
}

.Link-Container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
}


.Surf-Gif {
    width: 104vw;
    height:60vh;
}

.Carousel-Image {
    width: 50vw;
    height: 62vh;
}
