@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

.AboutUsSection {
  display: flex;
  flex-direction: row;
  gap: 48px;
  margin: auto;
  text-align: center;
  height: 80vh;
  box-sizing: content-box;
}

.AboutUsTextSection {
  display: flex;
  flex: 1.2;
  flex-direction: column;
  gap: 16px;
  width: 50vw;
  margin: auto;
  padding-left: 100px;
  padding-right: 100px;
}

.AboutUsHeaderText {
  text-align: center;
  font-family: 'Irish Grover';
  font-size: 3.2vw;
  padding-left: 4px;
  background-color: transparent;
  color: #F8E9D2;
  border: none;
}

.AboutUsFooterText {
  text-align: center;
  font-family: 'Krona One', sans-serif;
  font-size: 3vw;
  color: #F8E9D2;
  background-color: transparent;
}


.AboutUsText {
  font-family: 'Alike Angular', serif;
  font-size: 1.2vw;
  color: #F8E9D2;

}

.SurferGuy {
    height: 100%;
    width: 100%;
    margin: auto;
    flex: 1;
}

/* Styles the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

/* Styles the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Styles the scrollbar itself (width, etc.) */
::-webkit-scrollbar {
  width: 0px;
}

/* Hover effect for the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}