.HeaderMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-image: url("../../../assets/backgrounds/MobileBackground.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.HeaderRow {
  flex: 1;
  margin: auto;
  padding: 16px;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 50px;
  justify-content: center;
}

.Logo {
  justify-content: left;
  flex: 2;
  padding-top: 12px;
  margin-left: -30px;
  height: 80px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}


.menu-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dropdown-menu {
  background-color: #F8E9D2;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  width: 50px;
}


.dropdown-menu.active {
  position: absolute;
  top: 50px;
  right: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100px;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive {
  position: absolute;
  top: 50px;
  right: 10px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}


.dropDownItem {
  cursor: auto;
}

.dropdownItem img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}