.Header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2vw;
    padding-right: 2vw;
    border-bottom: 4px solid #F8E9D2;
    box-sizing: border-box;
}

.App-link {
color: #00A0FB;
    font-family: 'Irish Grover';
    cursor: pointer;
    font-size: 24px;
}

button {
    cursor: pointer;
}

.AppLogoWrapper {
    display: flex;
    justify-content: center;
    padding-left: 11%;
    align-items: center;
}

.App-logo {
  height: 250px;
  width: 190px;
  margin-bottom: -40px;
  margin-top: -30px;
  cursor: pointer;
}

.Link-Icon {
    height: 24px;
    width: 24px;
}

.Link-Row {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 8px;
}


.Button-Container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1vw;
}

.Shopping-Cart {
    cursor: pointer;
    height: 60px;
}

.Text {
  text-align: center;  
  font-family: 'Irish Grover';
  font-size: 40px;
  background-color: transparent;
  color: #F8E9D2;
  border: none;
}