.LandingPageMobileSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-top: -10px;
}

.App-Logo-Mobile {
    pointer-events: none;
    width: 100%;
    height: 100%;
}

.LandingPageMobileSection h1 {
    font-family: 'Krona One', sans-serif;
    font-size: 2.5rem;
    margin-top: 32px;
    margin-bottom: 20px;
}

.TextMobile {
    color: #F8E9D2;
}

.FooterText {
    font-family: 'Alike Angular', serif;
}

.BulletsMobile {
    font-family: 'Alike Angular', serif;
    font-size: 30px;
    list-style-position: inside;
    padding-left: 0;
    align-items: left;
    margin-top: 20px;
}

.Image {
    height: 200px;
    width: 100vw;
}